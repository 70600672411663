import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import Map from "./Map_with_real_API";
import { Container, Col, Row,} from "react-bootstrap";
import "./Main.css";

const MainPage = () => {
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated") || false
  );
  const [user, setUser] = useState(null);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticated");
    if (loggedInUser) {
      setAuthenticated(loggedInUser);
      setUser(localStorage.getItem("user"));
    }
  }, []);

  if (!authenticated) {
    return <Navigate replace to="/login" />;
  } else {
    return (
      <Container
        fluid
        className="Main-Container"
      >
        <Row id='Row'>
          <Col>
            <Map position={user} />
          </Col>
        </Row>
      </Container>
    );
  }
};

export default MainPage;
