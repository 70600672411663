import logo_group from "./logo/Group 9.png";
import "./Footer.css";
import { Container, Row, Col, Image} from "react-bootstrap";


const Footer = () => {
  return (
    <Container fluid className="Footer">
      <Row className="justify-content-center" id="Row">
        <Col className="Logo-Group" lg={{order: 'first'}} md={{order: 'last', cols: 12}} xl={5} sm={{order: 'last', cols: 12}}>
          <Image src={logo_group} className="Image" fluid/>
        </Col>

        <Col  md={{order: 'first', cols: 12}} sm={{order: 'first', cols: 12}} xs={{order: 'first'}} xl={5}>
          <a style={{ fontWeight: 'bold' }}>Χρήσιμοι σύνδεσμοι</a>
          <br />
          <a
            href="http://www.agrotikianaptixi.gr/el"
            target="_blank"
            rel="noopener noreferrer" className="Links"
          >
            Δικτυακός Τόπος Προγράμματος Αγροτικής Ανάπτυξης
          </a>
          <br />
          <a
            href="https://ec.europa.eu/agriculture/index_el"
            target="_blank"
            rel="noopener noreferrer" className="Links"
          >
            Δικτυακός Τόπος Διεύθυνση Γεωργίας και Αγροτικής Ανάπτυξης της
            Ευρωπαϊκής Επιτροπής
          </a>
          <br />
          <a href="https://ead.gr/" target="_blank" rel="noopener noreferrer" className="Links">
            Δικτυακός Τόπος Εθνικού Αγροτικού Δικτύου
          </a>
          <br />
          <a
            href="https://ec.europa.eu/eip/agriculture/"
            target="_blank"
            rel="noopener noreferrer" className="Links"
          ></a>
        </Col>
      </Row>
      {/* <Row className="justify-content-center">
        <Col >
          <p className="copyright">© Copyright 2024 RealTime Spraying. All RIGHTS RESERVED. - created by</p>
        </Col>
      </Row> */}
    </Container>
  );
};

export default Footer;
