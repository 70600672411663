import React, { useEffect, useState } from "react";
import AppLogoUrl from './icons/Group 54.png'
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import "./Login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await fetch("https://api.platform.realtimesprayingpalaiopanagia.gr/api/logout/", {
        method: "GET",
        credentials: "include",
      });

      if (response.ok) {
        localStorage.clear();
        document.cookie =
          "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("An error occurred during logout:", error);
    }

    try {
      const response = await fetch("https://api.platform.realtimesprayingpalaiopanagia.gr/api/auth/", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.authenticated){
          console.log(data);
          setUser(data);
        } else {
        console.error("Authentication failed");
        setErrorMessage("Λάθος Κωδικός Πρόσβασης ή Όνομα Χρήστη");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Κάτι πήγε λάθος. Προσπαθήστε ξανά σε λίγο.")
    }
  };

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user.role));
      localStorage.setItem("authenticated", "true");
      if (user.role === "Supervisor") {
        localStorage.setItem("supervisor", "true");
      }
      navigate("/");
    }
  // eslint-disable-next-line
  }, [user]);

  return (
    <Container fluid className="login-container">
      <Row>
        <Col md={6} style={{ padding: "0px" }} className="text-center">
          <div className="Login-Form">
            <h2 id="h2">Είσοδος στην πλατφόρμα</h2>
            <br />
            <p id="app-disc">
              Καλώς ήρθατε στην πλατφόρμα ζωντανής αναμετάδοσης ψεκασμού δακοκτονίας. Παρακαλώ συνδεθείτε για να συνεχίσετε
            </p>
            <br /> <br />
            {errorMessage && (
              <p id="ErrorMessage">{errorMessage}</p>
            )}
            <Form>
              <Form.Group className="mb-3 Input">
                <Form.Control
                  type="text"
                  placeholder="Username"
                  id="Input1"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  id="Input2"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
            </Form>
            <br />
            <Button id="Login-Button" onClick={handleLogin}>
              Είσοδος
            </Button>
          </div>
        </Col>
        <Col className="d-flex justify-content-center align-items-center" id="Map-Example" md={6}>
          <Image src={AppLogoUrl} className="AppLogo" fluid/>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
