import { useLocation } from "react-router-dom";
import logo from "./logo/Real-Time-Spraying.png";
import LogoutButton from "./LogoutButton";
import { useEffect, useState } from "react";
import "./Header.css";
import { Container, Row, Col, Image, Button, Stack } from "react-bootstrap";

const Header = () => {
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated") || false
  );
  const [supervisor, setSupervisor] = useState(
    localStorage.getItem("supervisor") || false
  );

  const handleLogoutSuccess = () => {
    setAuthenticated(false);
    setSupervisor(false);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    setSupervisor(localStorage.getItem("supervisor") || false);
  }, [pathname]);

  return (
    <Container fluid className="Header">
      <Row id="Row">
        <Col>
          <a
            href="https://realtimesprayingpalaiopanagia.gr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src={logo} alt="logo" className="Logo" />
          </a>
        </Col>
        <Col lg="auto" xs="auto">
          <div className="d-flex justify-content-between">
            <Stack direction="horizontal" gap={3}>
              {supervisor && (
                <div>
                  <a
                    href="https://api.platform.realtimesprayingpalaiopanagia.gr/admin/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button id="Admin-Button">Admin Panel</Button>
                  </a>
                </div>
              )}
              {pathname === "/" && (
                <div>
                  <LogoutButton onLogoutSuccess={handleLogoutSuccess} />
                </div>
              )}
            </Stack>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
