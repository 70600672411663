import React from "react";
import { useNavigate } from "react-router-dom";
import {Button, Image} from 'react-bootstrap'
import './Header.css'
import logoutIconUrl from './icons/logout alt 2.svg'

const LogoutButton = ({ onLogoutSuccess }) => {
  const navigate = useNavigate()
  const handleLogout = async () => {
    try {
      const response = await fetch("https://api.platform.realtimesprayingpalaiopanagia.gr/api/logout/", {
        method: "GET",
        credentials: "include",
      });

      if (response.ok) {
        console.log("Logged out successfully");
        onLogoutSuccess();
        localStorage.clear();
        document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        navigate('/login')
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("An error occurred during logout:", error);
    }
  };

  return <Button id='Logout-Button' onClick={handleLogout}>
    <a>Αποσύνδεση </a>
    <Image src={logoutIconUrl}/>
    </Button>;
};

export default LogoutButton;
