import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, CircleMarker, Polyline } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./Main.css";
import BlueTracIconUrl from "./icons/Group 62.svg";
import RedTracIconUrl from "./icons/Group 63.svg";
import MapIconUrl from "./icons/Icon awesome-map-marked.svg";
import ContactIconUrl from "./icons/Group 39.svg";
import okIconUrl from "./icons/Validation - check.svg";
import errorIconUrl from "./icons/Icon awesome-exclamation-triangle.svg";
import { Container, Row, Col, Table, Stack, Image } from "react-bootstrap";


const Map = () => {
  const [tractorData, setTractorData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [popupContent, setPopupContent] = useState();
  const [sprayer, setSprayer] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [currentTime, setCurrentTime] = useState(new Date());
  const [sprayingData, setSprayingData] = useState([]);
  const [nonSprayingData, setNonSprayingData] = useState([]);

  useEffect(() => {
    const timer = setInterval(() =>{
      setCurrentTime(new Date())
    },10*1000)
    return () =>{
      clearInterval(timer)
    }
  },[])

  const handleMarkerClick = (tractor) => {
    setPopupContent(tractor);
  };

  useEffect(() => {
    if (popupContent) {
      setSprayingData(popupContent.historical_data_spray || []);
      setNonSprayingData(popupContent.historical_data_nonspray || []);
      const popupTimestamp = new Date(popupContent.timestamp)
      const getSprayer = async () => {
        try {
          const response = await fetch(
            "https://api.platform.realtimesprayingpalaiopanagia.gr/api/getsprayer/",
            {
              method: "GET",
              credentials: "include",
              headers: new Headers({
                Accept: "application/json",
                "Content-Type": "application/json",
                "device-id": popupContent.id,
              }),
            }
          );
          const data = await response.json();
          setSprayer(data.user);
        } catch (error) {
          if (error.message === "Failed to fetch active user") {
            setErrorMessage("An error occurred while fetching active user");
          } else {
            setErrorMessage("No active user found for the given device ID");
          }
        }
      };
      getSprayer();
    }
    console.log(sprayingData)
  }, [popupContent]);

  useEffect(() => {
    if (sprayer) {
      setShowTable(true);
    }
  }, [sprayer]);

  useEffect(() => {
    const apiUrl = "https://api.platform.realtimesprayingpalaiopanagia.gr/api/getdevices/";

    const fetchData = () => {
      fetch(apiUrl, {
        method: "GET",
        credentials: "include",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          //console.log(data);
          setTractorData(data.data);
          //console.log(tractorData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    fetchData();

    // Set up an interval to fetch data from the API periodically (adjust the interval as needed)
    const intervalId = setInterval(fetchData, 10000); // Fetch data every 1 sec

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const tractorIcon = (alarm) => {
    const iconUrl = alarm ? RedTracIconUrl : BlueTracIconUrl;

    return L.divIcon({
      className: "custom-marker",
      html: `<img src="${iconUrl}" />`,
      iconSize: [45, 70],
      iconAnchor: [25, 75],
      popupAnchor: [0, 0],
    });
  };

  return (
    <Container fluid>
      <Row>
        <Col xl={4} lg={4} className="Detail-Col">
          <Stack gap={3}>
            <div id="Map-Info" className="text-center">
              <Image src={MapIconUrl} />
              <p>Επιλέξτε στον χάρτη για προβολή λεπτομερειών</p>
            </div>
            {showTable && (
              <div className="Sprayer-Info">
                <Table borderless size="sm" className="text-left">
                  <tbody>
                    <tr>
                      <td rowSpan={2}>
                        <Image src={ContactIconUrl} id="Contact-Icon" />
                      </td>
                      <td>
                        {sprayer.first_name} {sprayer.last_name}
                      </td>
                    </tr>
                    <tr>
                      <td>{sprayer.contact_phone}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}
            <div>
              {showTable && (
                <Table bordered size="sm" responsive id="Tractor-Info">
                  <tbody>
                    <tr>
                      <td>
                        <Image
                          src={
                            popupContent.wind_speed > 4 ||
                            popupContent.wind_relative_humidity < 75 ||
                            popupContent.temperature_in_celsius >28 ||
                            popupContent.applied_spraying_mix <300 ||
                            (currentTime - new Date(popupContent.timestamp))/60000 > 5 ||
                            popupContent.leak_alarm
                              ? errorIconUrl
                              : okIconUrl
                          }
                        />
                      </td>
                      <td>
                        <strong>ID μηχανήματος</strong>
                      </td>
                      <td>{popupContent.id}</td>
                    </tr>
                    <tr>
                      <td>
                        <Image
                          src={
                            (currentTime - new Date(popupContent.timestamp))/60000 > 5
                              ? errorIconUrl
                              : okIconUrl
                          }
                        />
                      </td>
                      <td>
                        <strong>Χρονοσήμανση</strong>
                      </td>
                      <td>{popupContent.timestamp}</td>
                    </tr>
                    <tr>
                      <td>
                        <Image
                          src={
                            popupContent.wind_speed > 4
                              ? errorIconUrl
                              : okIconUrl
                          }
                        />
                      </td>
                      <td>
                        <strong>Ταχύτητα Αέρα</strong>
                      </td>
                      <td>{popupContent.wind_speed}</td>
                    </tr>
                    <tr>
                      <td>
                        <Image src={okIconUrl} />
                      </td>
                      <td>
                        <strong>Κατεύθυνση Αέρα</strong>
                      </td>
                      <td>{popupContent.wind_direction}</td>
                    </tr>
                    <tr>
                      <td>
                        <Image
                          src={
                            popupContent.wind_relative_humidity < 75
                              ? errorIconUrl
                              : okIconUrl
                          }
                        />
                      </td>
                      <td>
                        <strong>Υγρασία</strong>
                      </td>
                      <td>{popupContent.wind_relative_humidity}</td>
                    </tr>
                    <tr>
                    <td>
                        <Image
                          src={
                            popupContent.temperature_in_celsius > 28
                              ? errorIconUrl
                              : okIconUrl
                          }
                        />
                      </td>
                      <td><strong>Θερμοκρασία</strong></td>
                      <td>{popupContent.temperature_in_celsius}</td>
                    </tr>
                    <tr>
                      <td>
                        <Image src={okIconUrl} />
                      </td>
                      <td>
                        <strong>Πίεση Βυτίου</strong>
                      </td>
                      <td>{popupContent.spraying_boom_pressure}</td>
                    </tr>
                    <tr>
                      <td>
                        <Image src={
                            popupContent.leak_alarm
                              ? errorIconUrl
                              : okIconUrl
                          } />
                      </td>
                      <td>
                        <strong>Διαρροή Βυτίου</strong>
                      </td>
                      <td>{popupContent.leak_alarm}</td>
                    </tr>
                    <tr>
                    <td>
                        <Image
                          src={
                            popupContent.applied_spraying_mix < 300
                              ? errorIconUrl
                              : okIconUrl
                          }
                        />
                      </td>
                      <td>
                        <strong>Εφαρμοσμένο μίγμα ψεκασμού</strong>
                      </td>
                      <td>{popupContent.applied_spraying_mix}</td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </div>
          </Stack>
        </Col>
        <Col>
          <MapContainer
            center={[36.8, 22.5]}
            zoom={9}
            className="Map-Container"
          >
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution='&copy; <a href="https://www.esri.com/en-us/home?srsltid=AfmBOooTThrxEyQ81Eo483JZk8JD--LZtQCBQIjeOWCD3t9Yk-FBK5Xb" target="_blank">ESRI     ....</a> '
            />
            {tractorData.map((tractor) => {
              const isHumidityAlarm = tractor.wind_relative_humidity < 75;
              const isWindAlarm = tractor.wind_speed > 4;
              const isTempAlarm = tractor.temperature_in_celsius >28;
              const isSprayingMix = tractor.applied_spraying_mix < 300;
              const leakAlarm = tractor.leak_alarm
              const lastTime = new Date(tractor.timestamp)
              const timeAlarm = (currentTime - lastTime)/60000 >5
              const hasAlarm = isHumidityAlarm || isWindAlarm || isTempAlarm || isSprayingMix || timeAlarm || leakAlarm;
              const alarmStyle = {
                color: hasAlarm ? "#A46666" : "#2E3B30",
                fontWeight: hasAlarm ? "medium" : "normal",
              };
              const timeAlarmStyle = {
                color: timeAlarm ? "#A46666" : "#2E3B30",
                fontWeight: timeAlarm ? "medium" : "normal",
              };
              const windAlarmStyle = {
                color: isWindAlarm ? "#A46666" : "#2E3B30",
                fontWeight: isWindAlarm ? "medium" : "normal",
              };
              const humidityAlarmStyle = {
                color: isHumidityAlarm ? "#A46666" : "#2E3B30",
                fontWeight: isHumidityAlarm ? "medium" : "normal",
              };
              const tempAlarmStyle = {
                color: isTempAlarm ? "#A46666" : "#2E3B30",
                fontWeight: isTempAlarm ? "medium" : "normal",
              };
              const sprayMixAlarmStyle = {
                color: isSprayingMix ? "#A46666" : "#2E3B30",
                fontWeight: isSprayingMix ? "medium" : "normal",
              };
              const leakAlarmStyle = {
                color: isSprayingMix ? "#A46666" : "#2E3B30",
                fontWeight: isSprayingMix ? "medium" : "normal",
              };
              const popupAlarm = hasAlarm ? "RedPopup" : "Popup";

              return (
                <Marker
                  key={tractor.ID}
                  position={[tractor.latitude, tractor.longitude]}
                  icon={tractorIcon(
                    isWindAlarm || isHumidityAlarm || isSprayingMix || isTempAlarm || timeAlarm || leakAlarm? true : false
                  )}
                  style={{ zIndexOffset: 1000 }}
                  className="Marker"
                  eventHandlers={{
                    click: () => handleMarkerClick(tractor),
                  }}
                >
                  <Popup className={popupAlarm}>
                    
                    <Table borderless size="sm" responsive>
                      <tbody>
                        <tr>
                          <td style={alarmStyle}>ID μηχανήματος</td>
                          <td style={alarmStyle}>{tractor.id}</td>
                        </tr>
                        <tr>
                          <td style={timeAlarmStyle}>Χρονοσήμανση</td>
                          <td style={timeAlarmStyle}>{tractor.timestamp}</td>
                        </tr>
                        <tr>
                          <td style={windAlarmStyle}>Ταχύτητα Αέρα</td>
                          <td style={windAlarmStyle}>{tractor.wind_speed}</td>
                        </tr>
                        <tr>
                          <td>Κατεύθυνση Αέρα</td>
                          <td>{tractor.wind_direction}</td>
                        </tr>
                        <tr>
                          <td style={humidityAlarmStyle}>Υγρασία</td>
                          <td style={humidityAlarmStyle}>
                            {tractor.wind_relative_humidity}
                          </td>
                        </tr>
                        <tr>
                          <td style={tempAlarmStyle}>Θερμοκρασία</td>
                          <td style={tempAlarmStyle}>{tractor.temperature_in_celsius}</td>
                        </tr>
                        <tr>
                          <td>Πίεση Βυτίου</td>
                          <td>{tractor.spraying_boom_pressure}</td>
                        </tr>
                        <tr>
                          <td style ={leakAlarmStyle}>Διαρροή Βυτίου</td>
                          <td style ={leakAlarmStyle}>{tractor.leak_alarm}</td>
                        </tr>
                        <tr>
                          <td style={sprayMixAlarmStyle} >Εφαρμοσμένο μίγμα ψεκασμού</td>
                          <td style={sprayMixAlarmStyle} >{tractor.applied_spraying_mix}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Popup>
                </Marker>
              );
            })}
            {sprayingData && (
              sprayingData.map((data, index) => (
                <CircleMarker
                  key={index}
                  center={[data.latitude, data.longitude]}
                  radius={5}
                  color="#ab9c7d"
          />
          
        ))
            )}
            {nonSprayingData.length > 0 && (
          <Polyline
            positions={nonSprayingData.map(data => [data.latitude, data.longitude])}
            color="#3388ff"
            weight={5}
            opacity={1}
          />
        )}
          </MapContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default Map;
